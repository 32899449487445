/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Text, Box, Flex, Image } from 'theme-ui';
import { Link } from 'gatsby';
import logo from '../assets/logo-transparent.png';
import Slide from 'react-reveal/Slide';

import bg from '../assets/hamburger.jpg';

const BusinessPage = () => {
  const routes = [
    {
      title: 'Home',
      route: '/',
    },
    {
      title: 'Places',
      route: '/places',
    },
    {
      title: 'AGW SONO Partners',
      route: '/AGWPartners',
    },
    {
      title: 'Spaces',
      route: 'https://agwpartners.com/availabilities/',
    },
    // {
    //   title: 'News',
    //   route: '/news',
    // },
    {
      title: 'Events',
      route: '/events',
    },
    {
      title: 'Gift Cards',
      newTab: true,
      route: 'https://app.yiftee.com/gift-card/onwashington',
    },
    {
      title: 'Blog',
      route: '/blog',
    },
  ];

  const renderNavigationMenu = () => (
    <Box
      sx={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '8',
        bg: 'text',
        width: '100%',
        scrollY: 'none',
        paddingTop: [5, 0],
      }}
    >
      <Flex sx={{ flexWrap: 'wrap', height: '100%' }}>
        <Box sx={{ width: ['100%', '50%', '33.3333%'], padding: [2, 3, 4] }}>
          <Image
            sx={{ maxWidth: '275px', width: '100%', mb: [4, 5] }}
            src={logo}
          />
          {routes.map((route, idx) => {
            if (route.route.includes('http')) {
              return (
                <Box sx={{ paddingLeft: 4 }}>
                  <Slide left delay={200 * idx}>
                    <Text
                      target="_blank"
                      rel="noopener noreferrer"
                      as="a"
                      href={route.route}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        textDecoration: 'none',
                        fontSize: [2, '20px'],
                        marginBottom: [3, 2, 3],
                        fontWeight: 400,
                        fontFamily: 'Lato',
                        textTransform: 'uppercase',
                        '::before': {
                          content: '""',
                          display: 'block',
                          width: 60,
                          height: 5,
                          backgroundColor: 'primary',
                          marginRight: 4,
                        },
                        '&:hover': {
                          color: 'primary',
                          transition: 'all .3s',
                        },
                      }}
                    >
                      {route.title}
                    </Text>
                  </Slide>
                </Box>
              );
            }
            return (
              <Box sx={{ paddingLeft: 4, display: 'flex' }}>
                <Slide left delay={200 * idx}>
                  <Link
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      textDecoration: 'none',
                      fontSize: [2, '20px'],
                      marginBottom: [3, 2, 3],
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      textTransform: 'uppercase',
                      '::before': {
                        content: '""',
                        display: 'block',
                        width: 60,
                        height: 5,
                        backgroundColor: 'primary',
                        marginRight: 4,
                      },
                      '&:hover': {
                        color: 'primary',
                        transition: 'all .3s',
                      },
                    }}
                    to={`${route.route}`}
                  >
                    {route.title}
                  </Link>
                </Slide>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: ['none', 'block'],
            backgroundImage: `url(${bg})`,
            width: ['50%', '50%', '66.66666%'],
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></Box>
      </Flex>

      {/* <Flex
        as='ul'
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingY: 6,
          paddingTop: 5,
          paddingX: 5,
          height: '100%',
          background: `url(${bg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}></Flex> */}
    </Box>
  );

  return renderNavigationMenu();
};

export default BusinessPage;

/*
  {routes.map(route => {
          if (route.route.includes('http')) {
            return (
              <Text
                target='_blank'
                rel='noopener noreferrer'
                as='a'
                href={route.route}
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: [5, 6, 8],
                  display: 'inline-block',
                  marginBottom: [2, 2, 3],
                  fontWeight: 'bold',
                  fontFamily: 'Lato',
                  textTransform: 'uppercase',
                  color: 'primary',
                  cusror: 'primary',
                  '&:hover': {
                    color: 'white',
                    transition: 'all .3s',
                  },
                }}>
                {route.title}
              </Text>
            );
          }
          return (
            <Link
              sx={{
                color: 'white',
                textDecoration: 'none',
                fontSize: [5, 6, 8],
                display: 'inline-block',
                marginBottom: [2, 2, 3],
                fontWeight: 'bold',
                fontFamily: 'Lato',
                textTransform: 'uppercase',
                color: 'primary',
                '&:hover': {
                  color: 'white',
                  transition: 'all .3s',
                },
              }}
              to={`${route.route}`}>
              {route.title}
            </Link>
          );
        })}
*/
