/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { Text, Box, Flex, Image } from 'theme-ui';
import HamburgerMenu from 'react-hamburger-menu';
import { useColorMode } from 'theme-ui';
import SEO from './SEO';

import '../styles/index.css';
import NavigationMenu from './NavigationMenu';
import Footer from './Footer';
import logo from '../assets/trans-white.png';

const Layout = ({ children, pageTitle }) => {
  const [colorMode, setColorMode] = useColorMode();

  const [open, setOpen] = useState(false);
  const routes = [
    {
      title: 'Home',
      route: '/',
    },
    {
      title: 'Places',
      route: '/places',
    },
    {
      title: 'AGW SONO Partners',
      route: '/AGWPartners',
    },
    {
      title: 'Spaces',
      route: 'https://agwpartners.com/availabilities/',
    },
    {
      title: 'Events',
      route: '/events',
    },
    {
      title: 'Blog',
      route: '/blog',
    },
    // {
    //   title: 'Art',
    //   route: '/art-on-washington',
    // },
    // {
    //   title: 'News',
    //   route: '/news',
    // },
  ];
  return (
    <>
      <SEO pageTitle={pageTitle} />
      <main sx={{ bg: '#fbfbfb' }}>
        {open ? <NavigationMenu /> : null}
        <Box
          onClick={() => setOpen(!open)}
          as="header"
          sx={{
            display: [
              'flex',
              'flex',
              `${
                pageTitle === 'Home' || pageTitle === 'Art OnWashington'
                  ? 'flex'
                  : 'none'
              }`,
            ],
            position: ['fixed', 'fixed', 'fixed'],
            top: ['20px', '20px', '20px'],
            right: ['20px', '20px', '20px'],
            backgroundColor: 'text',
            padding: 3,
            zIndex: 9,
            width: ['90px', '100px', '120px'],
            height: ['90px', '100px', '120px'],
            borderRadius: '9999px',
            border: '2px solid #F4BC33',
            justifyContent: 'center',
            alignItems: 'center',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text
              sx={{
                color: 'white',
                pr: [2, 2, 3],
                fontWeight: 'heading',
                textTransform: 'uppercase',
                fontFamily: 'lato',
                fontSize: [0, 2, 2],
              }}
            >
              Menu
            </Text>
            <HamburgerMenu
              isOpen={open}
              menuClicked={() => setOpen(!open)}
              width={18}
              height={15}
              strokeWidth={2}
              rotate={0}
              color="#F4BC33"
              borderRadius={0}
              animationDuration={0.5}
            />
          </Flex>
        </Box>
        <Box
          sx={{
            display: [
              'none',
              'none',
              `${
                pageTitle === 'Home' || pageTitle === 'Art OnWashington'
                  ? 'none'
                  : 'block'
              }`,
            ],
            background: 'black',
            paddingX: 4,
          }}
        >
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ padding: 3 }}>
              <Link to="/">
                <Image src={logo} sx={{ maxWidth: '225px' }}></Image>
              </Link>
            </Box>
            <Box>
              <Flex
                as="ul"
                sx={{
                  alignItems: 'flex-start',
                }}
              >
                {routes.map(route => (
                  <Link
                    target={route.newTab ? '_blank' : ''}
                    sx={{
                      color: 'white',
                      fontSize: ['15px'],
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      display: 'inline-block',
                      marginRight: '24px',
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      letterSpacing: '1px',
                    }}
                    to={`${route.route}`}
                  >
                    {route.title}
                  </Link>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Box>
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
