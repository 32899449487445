import React from 'react';
import { Helmet } from 'react-helmet';
import capitalize from '../helpers/capitalize';
import logo from '../assets/circle.png';
// import avatarCoverStringBuilder from '../helpers/avatarCoverStringBuilder';

const SEO = ({ pageTitle }) => {
  return (
    <Helmet>
      <title>{`OnWashington | ${pageTitle}`}</title>
      <html lang='en' />
      <meta
        name='description'
        content='OnWashington shines a spotlight on Washington Street Historic District, a vibrant corridor located in the coastal community of South Norwalk (SoNo), CT.'
      />
      {/* <meta
        name='image'
        content={avatarCoverStringBuilder(
          siteMetaData.avatar.imageBaseUrl,
          siteMetaData.avatar.imagePrefix
        )}
      /> */}
      <link rel='canonical' href={'https://onWashington.com'} />

      {/* OpenGraph tags */}
      <meta property='og:url' content={'https://onWashington.com'} />
      <meta property='og:title' content={capitalize(pageTitle)} />
      <meta property='og:description' content={'description'} />
      <meta property='og:image' content={logo} />
      <link
        id='favicon'
        rel='icon'
        href={logo}
        type='image/x-icon'
        data-react-helmet='true'
      />
      {/* <script> var _ctct_m = "42867e8cd797cdb7673d5ae6b9483924"; </script>
<script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script> */}
      {/* Twitter Card tags */}
      {/* <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={siteMetaData.links.twitter} />
      <meta name='twitter:title' content={capitalize(pageTitle)} />
      <meta name='twitter:description' content={siteMetaData.desc} />
      <meta
        name='twitter:image'
        content={avatarCoverStringBuilder(
          siteMetaData.avatar.imageBaseUrl,
          siteMetaData.avatar.imagePrefix
        )}
      /> */}
    </Helmet>
  );
};

export default SEO;
